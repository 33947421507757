.approve-button,
.reject-button {
  padding: 5px 10px;
  margin: 10px 10px 10px 0;
  font-size: 13px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approve-button {
  background-color: #fff;
  color: #28a745;
  border: 1px solid #28a745;
}

.reject-button {
  background-color: #fff;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.apprrejectDiv{
  display: flex;
  justify-content: center;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pdf-container {
  max-height: 100%; /* Ensure the PDF fits within the available space */
}
.scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
/* Add the following to your existing styles */

.document-popup {
  height: 80vh;
  width: 550px;
  overflow-y: auto; 
  display: flex;
  flex-direction: column; 
  flex-grow: 1;
}

.popup-content {
  flex-grow: 1; /* Allow the content to grow and take all available space */
  padding: 10px;
  overflow-y: auto; 
  display: flex;
  flex-direction: column; 
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
}

.metadata-button{
  background-color: rgb(66, 20, 95)!important;
  color: white;
  border: none;
  width: max-content;
  padding: 10px 20px;
  margin: 20px 30px 30px 35%;
  cursor: pointer;
  border-radius: 10px;
}
.chgview-button{
  background-color: #fff;
  color: rgb(66, 20, 95)!important;
  border: none;
  width: max-content;
  padding: 10px 20px;
  margin: 20px 30px 30px 35%;
  cursor: pointer;
  border: 2px solid rgb(66, 20, 95)!important;
  border-radius: 10px;
}
pre {
  background-color: #fff;
  padding: 30px;
  margin: 0 40px 30px 30px!important;
  border-radius: 4px;
  overflow-x: auto;
}
.metadata-sectionn{
  padding: 20px;
  width: 500px;
}
/* .documentpdf {
  max-height: 100%; /* Ensure the PDF fits within the available space */
/*} */