/*

TemplateMo 569 Edu Meeting

https://templatemo.com/tm-569-edu-meeting

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer
10. preloader
11. search
12. portfolio

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
  color: #3CF;
}

a:hover {
	color: #FC3;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #2a2a2a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/

html,
body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #f5a425;
  color: #fff;
}

::-moz-selection {
  background: #f5a425;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.main-button-red a {
  font-size: 13px;
  color: #fff;
  background-color: #3498db;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
}

.main-button-red a:hover {
  opacity: 0.9;
}

.main-button-yellow a {
  font-size: 13px;
  color: #fff;
  background-color: #f5a425;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
}

.main-button-yellow a:hover {
  opacity: 0.9;
}

.section-heading h2 {
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 50px; 
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(250,250,250,0.15);
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}


/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.sub-header {
  background-color: #1f272b;
  position: relative;
  z-index: 1111;
}

.sub-header .left-content p {
  color: #fff;
  padding: 8px 0px;
  font-size: 13px;
}

.sub-header .right-icons {
  text-align: right;
  padding: 8px 0px;
}

.sub-header .right-icons ul li {
  display: inline-block;
  margin-left: 15px;
}

.sub-header .right-icons ul li a {
  color: #fff;
  font-size: 14px;
  transition: all .3s;
}

.sub-header .right-icons ul li a:hover {
  color: #f5a425;
}

.sub-header .left-content p em {
   font-style: normal;
   color: #f5a425;
}

.background-header {
  background-color: #fff!important;
  height: 80px!important;
  position: fixed!important;
  top: 0!important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}

.background-header .main-nav .nav li a {
  color: #1e1e1e!important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #1e1e1e!important;
}

.background-header .main-nav .nav li:hover a {
  color: #fb5849!important;
}

.background-header .nav li a.active {
  color: #fb5849!important;
}

.header-area { 
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  background-color: rgba(250,250,250,0.15)!important;
  height: 100px;
  width: 100%;
}

.header-area .main-nav .logo {
  line-height: 100px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
  line-height: 75px;
}

.background-header .nav {
  margin-top: 20px !important;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 20px;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #f5a425!important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #f5a425!important;
  opacity: 1;
}

.header-area .main-nav .nav li.has-sub {
  position: relative;
  padding-right: 15px;
}

.header-area .main-nav .nav li.has-sub:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 5px;
  top: 12px;
}

.background-header .main-nav .nav li.has-sub:after {
  color: #1e1e1e;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 40px;
  opacity: 0;
  transition: all .3s;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a!important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.has-sub ul li a:hover {
  background: #fff;
  color: #f5a425!important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.has-sub ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.has-sub:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
  height: 100px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
  color: #f5a425;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 7px;
    padding-right: 7px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #f5a425!important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #f5a425!important;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }
  .header-area .main-nav .nav li.has-sub:after {
    color: #3B566E;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
my nav
--------------------------------------------- 
*/



/* 
---------------------------------------------
my nav
--------------------------------------------- 
*/


/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

.main-banner {
  position: relative;
  max-height: 100%;
  overflow: hidden;
  margin-bottom: -7px;
}

#bg-video {
    min-width: 100%;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    z-index: -1;
}

#bg-video::-webkit-media-controls {
    display: none !important;
}

.video-overlay {
    position: absolute;
    background-color: rgba(31,39,43,0.75);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
}

.main-banner .caption {
  position: absolute;
  margin-top: 30%!important;
  transform: translateY(-50%);
}

.main-banner .caption h6 {
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 em {
  font-style: normal;
  color: #f5a425;
  font-weight: 900;
}

.main-banner .caption p {
  color: #c0c0c0!important;
  font-size: 18px;
  max-width: 550px;
}

.main-banner .caption .main-button-red {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {

  .main-banner .caption h6 {
    font-weight: 500;
  }

  .main-banner .caption h2 {
    font-size: 36px;
  }

}


/*
---------------------------------------------
services
---------------------------------------------
*/

.services {
  margin-top: -135px;
  position: absolute;
  width: 100%;
}

.services .item {
  background-image: url(../../img/service-item-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  padding: 40px;
}

.services .item .icon {
  max-width: 60px;
  margin: 0 auto;
}

.services .item h4 {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.services .item p {
  color: #fff;
  font-size: 13px;
}

.services .owl-nav {
  display: inline-block !important;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-25px);
}
    
.services .owl-nav .owl-prev{
  margin-right: 10px;
  outline: none;
  position: absolute;
  left: -80px;
}

.services .owl-nav .owl-prev span,
.services .owl-nav .owl-next span {
  opacity: 0;
}

.services .owl-nav .owl-prev:before {
  display: inline-block;
  font-family: 'FontAwesome';
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: '\f104';
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}

.services .owl-nav .owl-prev {
  opacity: 1;
  transition: all .5s;
}

.services .owl-nav .owl-prev:hover {
  opacity: 0.9;
}

.services .owl-nav .owl-next {
  opacity: 1;
  transition: all .5s;
}

.services .owl-nav .owl-next:hover {
  opacity: 0.9;
}

.services .owl-nav .owl-next{
  margin-left: 10px;
  outline: none;
  position: absolute;
  right: -85px;
}

.services .owl-nav .owl-next:before {
  display: inline-block;
  font-family: 'FontAwesome';
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: '\f105';
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}


/*
---------------------------------------------
upcoming meetings
---------------------------------------------
*/

section.upcoming-meetings {
  background-image: url(../../img/meetings-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px;
}

section.upcoming-meetings .section-heading {
  text-align: center;
}

section.upcoming-meetings .categories {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  margin-right: 45px;
}

section.upcoming-meetings .categories h4 {
  font-size: 18px;
  font-weight: 600;
  color: #1f272b;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

section.upcoming-meetings .categories ul li {
  display: inline-block;
  margin-bottom: 15px;
}

section.upcoming-meetings .categories ul li a {
  font-size: 15px;
  color: #1f272b;
  font-weight: 500;
  transition: all .3s;
}

section.upcoming-meetings .categories ul li a:hover {
  color: #3498db;
}

section.upcoming-meetings .categories .main-button-red {
  border-top: 1px solid #eee;
  padding-top: 30px;
  margin-top: 15px;
}

section.upcoming-meetings .categories .main-button-red a {
  width: 100%;
  text-align: center;
}

.meeting-item {
  margin-bottom: 30px;
}

.meeting-item .thumb {
  position: relative;
}

.meeting-item .thumb img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.meeting-item .thumb .price {
  position: absolute;
  left: 20px;
  top: 20px;
}

.meeting-item .thumb .price span {
  font-size: 16px;
  color: #1f272b;
  font-weight: 600;
  background-color: rgba(250,250,250,0.9);
  padding: 7px 12px;
  border-radius: 10px;
}

.meeting-item .down-content {
  background-color: #fff;
  padding: 30px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.meeting-item .down-content .date {
  float: left;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
}

.meeting-item .down-content .date h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #3498db;
}

.meeting-item .down-content .date span {
  display: block;
  color: #1f272b;
  font-size: 22px;
  margin-top: 7px;
}

.meeting-item .down-content h4 {
  font-size: 18px;
  color: #1f272b;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-item .down-content p {
  margin-left: 50px;
  color: #1f272b;
  font-size: 14px;
}



/*
---------------------------------------------
apply now
---------------------------------------------
*/

section.apply-now {
  background-image: url(../../img/apply-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px;
}

section.apply-now .item {
  background-color: rgba(250,250,250,0.15);
  padding: 40px;
  margin-bottom: 30px;
}

section.apply-now .item h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

section.apply-now .item p {
  color: #fff;
  margin-bottom: 20px;
}

.accordions {
  border-radius: 20px;
  padding: 40px;
  background-color: #fff;
  margin-left: 45px;
}
.accordions .accordion {
  border-bottom: 1px solid #eee;
}
.accordions .last-accordion {
  border-bottom: none;
}
.accordion-head {
  padding: 20px;  
  font-size: 18px;
  font-weight: 700;
  color: #1f272b;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  border-bottom: 1px solid #fff;
}
@media screen and (min-width: 768px) {
  .accordion-head {
    padding: 1rem;
    font-size: 1.2rem;
  }
}
.accordion-head .icon {
  float: right;
  transition: transform 200ms ease-in-out;
}
.accordion-head.is-open {
  color: #f5a425;
  border-bottom: none;
}
.accordion-head.is-open .icon {
  transform: rotate(45deg);
}
.accordion-body {
  overflow: hidden;
  height: 0;
  transition: height 300ms ease-in-out;
  border-bottom: 1px solid #fff;
}
.accordion-body > .content {
  padding: 20px;
  padding-top: 0;
}


/* 
---------------------------------------------
courses
--------------------------------------------- 
*/

section.our-courses {
  background-image: url(../../img/meetings-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 130px;
}

.our-courses .item .down-content {
  background-color: #fff;
}

.our-courses .item .down-content h4 {
  padding: 25px;
  font-size: 18px;
  color: #1f272b;
  text-align: center; 
  border-bottom: 1px solid #eee;
}

.our-courses .item .down-content .info {
  padding: 25px;
}

.our-courses .item .down-content .info ul li {
  display: inline-block;
  margin-right: 1px;
}

.our-courses .item .down-content .info ul li i {
  color: #f5a425;
  font-size: 14px;
}

.our-courses .item .down-content .info span {
  color: #3498db;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
  display: inline-block;
  width: 100%;
}

.our-courses .owl-nav {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-45px);
}

.our-courses .owl-dots {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}

.our-courses .owl-dots .owl-dot {
  transition: all .5s;
  width: 7px;
  height: 7px;
  background-color: #fff;
  margin: 0px 5px;
  border-radius: 50%;
  outline: none;
}

.our-courses .owl-dots .active {
  width: 24px;
  height: 8px;
  border-radius: 4px;
}
    
.our-courses .owl-nav .owl-prev{
  margin-right: 10px;
  outline: none;
  position: absolute;
  left: -80px;
}

.our-courses .owl-nav .owl-prev span,
.our-courses .owl-nav .owl-next span {
  opacity: 0;
}

.our-courses .owl-nav .owl-prev:before {
  display: inline-block;
  font-family: 'FontAwesome';
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: '\f104';
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}

.our-courses .owl-nav .owl-prev {
  opacity: 1;
  transition: all .5s;
}

.our-courses .owl-nav .owl-prev:hover {
  opacity: 0.9;
}

.our-courses .owl-nav .owl-next {
  opacity: 1;
  transition: all .5s;
}

.our-courses .owl-nav .owl-next:hover {
  opacity: 0.9;
}

.our-courses .owl-nav .owl-next{
  margin-left: 10px;
  outline: none;
  position: absolute;
  right: -85px;
}

.our-courses .owl-nav .owl-next:before {
  display: inline-block;
  font-family: 'FontAwesome';
  color: #1e1e1e;
  font-size: 25px;
  font-weight: 700;
  content: '\f105';
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
}


/*
---------------------------------------------
our facts
---------------------------------------------
*/

section.our-facts {
  background-image: url(../../img/facts-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px 125px 0px;
}

section.our-facts h2 {
  font-size: 38px;
  color: #fff;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}

.count-area-content {
  text-align: center;
  background-color: rgba(250,250,250,0.15);
  border-radius: 20px;
  padding: 25px 30px 35px 30px;
  margin: 15px 0px;
}

.percentage .count-digit:after {
  content: '%';
  margin-left: 3px;
}

.count-digit {
    margin: 5px 0px;
    color: #f5a425;
    font-weight: 700;
    font-size: 36px;
}
.count-title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.5px;
}

.new-students {
  margin-top: 45px;
}

section.our-facts .video {
  text-align: center;
  margin-left: 70px;
  background-image: url(../../img/video-item-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
}

section.our-facts .video img {
  padding: 170px 0px;
  max-width: 56px;
}


/* 
---------------------------------------------
contact us
--------------------------------------------- 
*/

section.contact-us {
  background-image: url(../../img/meetings-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0px 0px 0px;
}

section.contact-us #contact {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
}

section.contact-us #contact h2 {
  text-transform: uppercase;
  color: #1f272b;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}

section.contact-us #contact input {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 0px 15px;
  margin-bottom: 30px;
}

section.contact-us #contact textarea {
  width: 100%;
  min-height: 140px;
  max-height: 180px;
  border-radius: 20px;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 500;
  color: #7a7a7a;
  padding: 15px;
  margin-bottom: 30px;
}

section.contact-us #contact button {
  font-size: 13px;
  color: #fff;
  background-color: #3498db;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s;
  border: none;
  outline: none;
}

section.contact-us #contact button:hover {
  opacity: 0.9;
}

section.contact-us .right-info {
  background-color: #3498db;
  border-radius: 20px;
  padding: 40px;
}

section.contact-us .right-info ul li {
  display: inline-block;
  border-bottom: 1px solid rgba(250,250,250,0.15);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

section.contact-us .right-info ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

section.contact-us .right-info ul li h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

section.contact-us .right-info ul li span {
  display: block;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.footer {
  text-align: center;
  margin-top: 140px;
  border-top: 1px solid rgba(250,250,250,0.15);
  padding: 50px 0px;
}
.footer p {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
}

.footer p a {
  color: #f5a425;
}


/*
---------------------------------------------
heading page
---------------------------------------------
*/

section.heading-page {
  background-image: url(../../img/heading-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 230px;
  padding-bottom: 110px;
  text-align: center;
}

section.heading-page h6 {
  margin-top: 0px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}

section.heading-page h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}


/*
---------------------------------------------
upcoming meetings page
---------------------------------------------
*/

section.meetings-page {
  background-image: url(../../img/meetings-page-bg.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 0px;
}

section.meetings-page .filters {
  text-align: center;
  margin-bottom: 60px;
}

section.meetings-page .filters li {
  font-size: 13px;
  color: #3498db;
  background-color: #fff;
  padding: 11px 30px;
  display: inline-block;
  border-radius: 22px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all .3s;
  cursor: pointer;
  margin: 0px 3px;
}

section.meetings-page .filters ul li.active,
section.meetings-page .filters ul li:hover {
  background-color: #3498db;
  color: #fff;
}

section.meetings-page .pagination {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  display: inline-block;
}

section.meetings-page .pagination ul li {
  display: inline-block;
}

section.meetings-page .pagination ul li a {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  color: #1f272b;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  transition: all .3s;
}

section.meetings-page .main-button-red {
  text-align: center;
}

section.meetings-page .main-button-red a {
  padding: 12px 60px;
  text-align: center;
  margin-top: 30px;
}

section.meetings-page .pagination ul li.active a,
section.meetings-page .pagination ul li a:hover {
  background-color: #3498db;
  color: #fff;
}

.meeting-single-item .thumb {
  position: relative;
}

.meeting-single-item .thumb img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.meeting-single-item .thumb .price {
  position: absolute;
  left: 20px;
  top: 20px;
}

.meeting-single-item .thumb .price span {
  font-size: 16px;
  color: #1f272b;
  font-weight: 600;
  background-color: rgba(250,250,250,0.9);
  padding: 7px 12px;
  border-radius: 10px;
}

.meeting-single-item .down-content {
  background-color: #fff;
  padding: 40px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.meeting-single-item .thumb .date {
  position: absolute;
  background-color: rgba(250,250,250,0.9);
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 15px 0px;
  border-radius: 10px;
  right: 20px;
  top: 20px;
}

.meeting-single-item .thumb .date h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #3498db;
}

.meeting-single-item .thumb .date span {
  display: block;
  color: #1f272b;
  font-size: 22px;
  margin-top: 7px;
}

.meeting-single-item .down-content h4 {
  font-size: 22px;
  color: #1f272b;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-single-item .down-content h5 {
  font-size: 18px;
  color: #1f272b;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 15px;
}

.meeting-single-item .down-content p {
  color: #1f272b;
  font-size: 14px;
}

.meeting-single-item .down-content p.description {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eee;
}

.meeting-single-item .down-content .share {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #eee;
}

.meeting-single-item .down-content .share h5 {
  float: left;
  margin-right: 10px;
  margin-bottom: 0px;
}

.meeting-single-item .down-content .share ul li {
  display: inline;
}

.meeting-single-item .down-content .share ul li a {
  font-size: 14px;
  color: #1f272b;
  transition: all .3s;
}

.meeting-single-item .down-content .share ul li a:hover {
  color: #f5a425;
}

/* Meeting item column */
.templatemo-item-col {
	width: 31%;
}

@media (max-width: 992px) {
	.templatemo-item-col {
		width: 45%;
	}
}

@media (max-width: 767px) {
	.templatemo-item-col {
		width: 100%;
	}
}

/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1300px) {
  .services .owl-nav .owl-next{
    right: -30px;
  }
  .services .owl-nav .owl-prev{
    left: -25px;
  }
  .our-courses .owl-nav .owl-next{
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -25px;
  }
}

@media (max-width: 1200px) {
  .services .owl-nav .owl-next{
    right: -70px;
  }
  .services .owl-nav .owl-prev{
    left: -65px;
  }
  .our-courses .owl-nav .owl-next{
    right: -70px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -65px;
  }
}

@media (max-width: 1085px) {
  .services .owl-nav .owl-next{
    right: -30px;
  }
  .services .owl-nav .owl-prev{
    left: -25px;
  }
  .our-courses .owl-nav .owl-next{
    right: -30px;
  }
  .our-courses .owl-nav .owl-prev{
    left: -25px;
  }
}

@media (max-width: 1005px) {
  .services .owl-nav .owl-next{
    display: none;
  }
  .services .owl-nav .owl-prev{
    display: none;
  }
  .our-courses .owl-nav .owl-next{
    display: none;
  }
  .our-courses .owl-nav .owl-prev{
    display: none;
  }
}

@media (max-width: 992px) {

  .main-banner .caption {
    top: 60%;
  }

  .main-banner .caption h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
  }

  .main-banner .caption .main-button-red {
    margin-top: 15px;
  }

  .services {
    margin-top: 60px;
  }

  section.upcoming-meetings {
    padding-top: 400px;
  }

  section.upcoming-meetings .categories {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .accordions {
    margin-left: 0px;
  }

  .new-students {
    margin-top: 15px;
  }

  section.our-facts .video {
    margin-left: 0px;
    margin-top: 15px;
  }

  section.contact-us #contact {
    margin-bottom: 30px;
  }

}

@media (max-width: 767px) {

  .sub-header .left-content p {
    display: none;
  }

  .sub-header .right-icons {
    text-align: center;
  }

  .main-nav .nav .sub-menu {
    display: none;
  }

  .header-area .main-nav .nav li ul.sub-menu li a {
    color: #1f272b;
  }

}




  /* Ends */

  /* Light box Starts */

  html.lb-disable-scrolling {
    overflow: hidden;
    /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
  
  .lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95);
    opacity: 0.95;
    display: none;
  }
  
  .lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
  }
  
  .lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 5px;
  }
  
  .lightbox a img {
    border: none;
  }
  
  .lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: white;
  }
  
  .lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
  }
  
  .lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(../../img/loading.gif) no-repeat;
  }
  
  .lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  
  .lb-container > .nav {
    left: 0;
  }
  
  .lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
  }
  
  .lb-prev, .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
  }
  
  .lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(../../img/prev.png) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(../../img/next.png) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-data {
    padding: 0 4px;
    color: #ccc;
  }
  
  .lb-data .lb-details {
    margin-top: 10px;
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }
  
  .lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
  }
  
  .lb-data .lb-caption a {
    color: #4ae;
  }
  
  .lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }
  
  .lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    margin-top: 15px;
    background: url(../../img/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  .lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  /* Light box Ends */