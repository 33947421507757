

/*----Hero-section----*/

.home-hero
{
    overflow-y: hidden;
    background: linear-gradient( rgba(243, 232, 135, 0.4) ,rgba(138, 227, 217, 0.4)), url("../../images/images/hero-image.jpg") no-repeat bottom left ;    
    /* background-image: url(images/hero-1.jpg); */
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    height: 65vh;
    width: auto;
    align-items: flex-end;
    display: flex;

}

.hero__image{
    width: 100%;
    margin: 0 auto;

}

.hero__box{
    color: whitesmoke;
    margin: 20rem 10rem 5rem; /*For left side*/
    width: 450px;
    height: 220px;
    overflow:hidden;
    background-color: rgb(0, 159, 172);
    border-bottom: 5px red solid;  
    text-align: left;

}

.hero__box-feature{
    font-size: 2rem;
    text-transform: capitalize;
    margin: 1rem 2rem auto;
    text-align: left;
}



/*==========HERO-BUTTON=======*/

.btn-know-us{
    margin: 1rem 2rem;

}

.btn--animated  {
    animation: movefromBottom 2s ease-out;

}

.btn:link,
.btn:visited{
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 3rem;
    display: inline-block; 
    /* border-radius: 1rem; */
    transition: all .5s ease;
    position: relative;
    font-size: 2rem;
}

.btn:hover{
    transform: translateY(-3px); 
    box-shadow: 0 1rem 2rem rgba(black, .2); 
    background-color: rgb(66, 20, 95);
    border-bottom: rgb(176, 72, 241) solid 7px;
    color: whitesmoke;

}

.btn:active{
    transform: translateY(-1px); /*To show that the button is clicked and a slight downward action is shown*/
    box-shadow: 0 .5rem 1rem rgba(black, .2);
}

.btn--white{
    background-color:rgba(66, 20, 95);
    color: white;
}


/*===================Features section================*/
.feature-section{
    width: 100%;
    background-color: rgb(66, 20, 95);
    margin: 0 auto;

}

.feature__row{
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    height: 25vh;
    width: 70vw;
    background-color: rgb(66, 20, 95);
    
}

.feature-box{
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgb(66, 20, 95);
    margin: auto;
    padding: 1.2rem;
    width: 300px;
    height: 200px;
    text-align: center!important;
    /* border-radius: 1em; */
    
}
.feature-box img{
    height: 70px;
    width: 70px;
}

.feature-box:hover{
    
    /* background-color: rgb(0, 159, 172); */
    transform: translateY(-1.2rem) translateY(1.1rem) scale(1.1); 
    transition: all .5s ease;

}
.feature-box__text{
    color: whitesmoke;
    text-transform: capitalize;
    margin-top: 1rem;
}

/*===================Account section================*/
.account__property{
    display: flex;
    justify-content: center;
    margin: 10rem auto 0;
    /* background: linear-gradient(to bottom left, rgb(127, 30, 217), rgba(171,224,255,1)); */
    background: linear-gradient(to top left, rgb(127, 30, 217,1), rgba(171,224,255,1));
    border-radius: 10px 10px 0 0;
    width: 70vw;
    border-bottom: solid red 7px;
}

.account_property-div{
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 2rem 1rem;
    display: flex;
    justify-content: center;
    max-height: 55vh;

}

.account__property__box{
    width: 30vw;
    height: auto;
    margin: 5rem auto;

}

.account__property__picture-box{
    margin-right: -.5rem;
    width: 40vw;
    height: 40vh;
    background: url(../../images/images/advice.jpg) no-repeat center;
    background-position: cover;  
    border-radius: 10px;
}


.account__property__text-box{
    color: rgb(0, 159, 172);
    height: 30vw;
    margin-left: -.5rem;
    text-transform: capitalize;
    font-size: 1rem;

}

.account__property__text-box h2{
    color: white;
    letter-spacing: 1px;
    margin: 1rem 2rem;
    font-size: 2.4rem;
    text-align: center;

}

.account__property__text__information{
    text-transform: capitalize;
    color: whitesmoke;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2em;
    justify-content: center;
    letter-spacing: .8px;

}


/*===================Footer section================*/

.footered{
    text-align: center!important;
    overflow-x: hidden;
    overflow-y: hidden;
    /* background: linear-gradient(to bottom left, rgb(127, 30, 217), rgba(171,224,255,1)); */
    background-color: rgb(66, 20, 95)!important;
    margin: 10rem auto 0;
    max-height: 27vh;
    overflow-y: hidden;
}

.footer__text__box{
    margin: 2rem auto;
    background-color: rgb(0, 159, 172);
    border-bottom: 5px red solid;  
    width: 500px;
}

.footer__text{
    padding: 1rem;
    text-transform: uppercase;
    color: rgb(66, 20, 95);
    font-size: 2.4rem;
}

.footer__icon__div{
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    margin: 1rem auto;
    width: 500px;
}
.footer__icon__box{
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgb(66, 20, 95);
    margin: 0 auto;
    width: 70px;
    height: 70px;
    padding: .5rem;
   
}
.footer__icon{
    justify-content: center;
}
.footer__icon img{
    width: 40px;
}
.footer__icon__box:hover{
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translateY(-1.2rem) translateY(1.2rem) scale(1.3);
    transition: all .5s ease;
    border-bottom: solid 3px whitesmoke;
}



/*===================MODAL operations================*/

/* .modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform:  translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out;
    border: 5px solid white;
    border-radius: 10px;
    z-index: 2;
    background-color: white;
    width: 800px;
    max-width: 80%;

}

.modal.active{
    transform:  translate(-50%, -50%) scale(1);

}
.modal-header{
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform:capitalize;

}

.modal-header .title{
    font-size: 2rem;
    font-weight: bold;
}



.modal-body .close-button{
    width: 100px;
    height: 50px;
    font-weight: bold;
    border: 2px red solid;
    background-color: white;
    color: red;

}

.close-button-div{
    padding: 20px;
    justify-content: center;
}

.close-button-div button:hover{
    background-color: red ;
    color: white;
}

#overlay{
    position: fixed;
    /* opacity: 0; */
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 500ms ease-in-out;
    pointer-events: none;
}

#overlay.active{
    opacity: 1;
    pointer-events: all;
    background-color: rgba(0,0,0, .4); 
} */
table{
    table-layout: fixed;
    width: 100%;
}
td{
    padding: 1rem;
    /* word-break: break-all; */
    word-wrap: break-word;
}