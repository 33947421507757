.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    height: 75vh;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    margin-right: 20px;
}

.right-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 60%;
    margin-left: 20px;
    overflow: hidden;
    height: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 60px;
}

.input-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.input-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #2ea3f2;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.input-container button:hover {
    background-color: #1d9aed;
}

.response-container {
    background: #f8f9fa;
    color: #212529;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
}

pre {
    text-align: left;
    margin: 0;
}

.separator {
    width: 100%;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}

.linee {
    height: 100%;
    width: 1.2px;
    background-color: #d4d4d4;
}

.instext{
    margin-bottom: 10px;
}
h3{
    margin-bottom: 10px!important;
}